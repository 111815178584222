/* create-react-app */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* lineup stuff */
html {
  background: url(./spotlist-bg.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height:100%;
  min-height:100%;
}

option {
  color: black;
}

body {
  background-color: transparent;
  line-height: 100%;
  margin: 0 auto;
  max-width: 600px;
  min-height:100%;
}

div.logo-container {
 padding-bottom: 0px !important;
 padding-top: 5px !important;
}

img#logo {
  width: 150px;
}

.lineupIcon {
  color: #edf0fb;
}

div.playlist-nav-buttons {
  padding-bottom: 15px;
  padding-top: 10px;
}

.playlist-nav-option-container {
  padding-bottom: 5px;
}

.playlist-nav-field-container {
  padding-bottom: 5px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: rgba(255, 255, 255, 0);
  border-color: white;
}

.nav-tabs {
  border-bottom: none;
  padding-bottom: 5px;
}

li.nav-item {
  padding: 10px;
}

.nav-tabs .nav-link {
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, .25);
  border-style: solid;
  border-width: 1px;
  border-radius: 0px;
  color: rgba(255, 255, 255, .4) !important;
  font-weight: 300;
  padding-bottom: 10px;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 10px;
  text-transform: uppercase;
  width: 100%;
}

.nav-tabs .nav-link.active {
  background-color: rgba(255, 255, 255, 0);
  border-color: white;
  border-style: solid;
  border-width: 2px;
  color: white !important;
  font-weight: 400;
  padding-left: 0px;
  padding-right: 0px;
}

input#newPlaylistNameInput {
  background-color: rgba(255, 255, 255, .07);
  border: none;
  font-size: 18px;
  line-height: 18px;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
  width: 70%;
  text-transform: uppercase;
  border-radius: 0px;
}

input#newPlaylistNameInput.callout {
  border: 1px solid red;
}

div#goButtonContainer div.unit {
  padding-bottom: 20px;
}

button.go-button {
  background-color: #95b30a;
  border: none;
  font-size: 20px;
  height: 72px;
  letter-spacing: .04em;
  padding-bottom: 10px;
  padding-top: 10px;
  text-transform: uppercase;
  width: 70%;
  border-radius: 0px;
}

.btn:disabled {
  background-color: #95b30a;
  border-color: #95b30a;
}


button.input-clear-button, button.input-clear-button:hover {
  border: none;
  border-radius: 0px;
  background-color: #95b30a;
}

span.existing-playlists-error-text {
  color: #edf0fb;
  font-size: 18px;
  text-transform: uppercase;
}

select#existingPlaylists {
  background-color: rgba(255, 255, 255, .07);
  border: none;
  border-radius: 0;
  font-size: 18px;
  line-height: 1;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 24px;
  padding-top: 15px;
  text-align-last: center;
  width: 100%;
}

/*LABEL FOR SELECT*/
label#existingPlaylistsLabel { position: relative; display: inline-block; width: 100%;}
/*DOWNWARD ARROW (25bc)*/
label#existingPlaylistsLabel::after
{
    content                 : "\25bc";
    position                : absolute;
    top                     : 0;
    right                   : 0;
    bottom                  : 0;
    width                   : 20pt;
    line-height             : 36pt;
    vertical-align          : middle;
    text-align              : center;
    background              : rgba(255, 255, 255, 0);
    color                   : #919691;
    pointer-events          : none;
}

div.enter-playlist-name-container span {
  text-transform: uppercase;
}

div#searchBox div.unit {
  padding-top: 20px;
}

h2 {
  font-size: 30px;
  line-height: 166%;
}

div#introText div.unit {
  margin-top: 0;
  padding: 0;
}

div#introText h2 {
  margin: 0;
  padding-top: 10px;
}

.font-main {
  color: #edf0fb;
  font-weight: 300;
  letter-spacing: 0.1em;
}

.font-main:focus {
  color: #edf0fb;
}

.font {
  font-family: 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
}

.font-light {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
}

input#searchInput {
  background-color: rgba(255, 255, 255, .07);
  border: 0;
  font-size: 18px;
  line-height: 18px;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
  text-transform: uppercase;
  width: 70%;
  border-radius: 0px;
}

span.no-search-results-text {
  color: #edf0fb;
  font-size: 18px;
  text-transform: uppercase;
}

span.search-error-text {
  color: #edf0fb;
  font-size: 18px;
  text-transform: uppercase;
}

div.search-results {
  padding-top: 20px;
}

div.search-result {
  align-items: center;
  background-color: rgba(255, 255, 255, .07);
  display: flex;
  justify-content: center;
  margin-top: 1px;
  text-align: center;
}

div.search-result-container {
  display: inline-block;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.artist-thumbnail-container {
  line-height: 0;
}

span.artist-thumbnail {
  border-radius: 50%;
  display: inline-block;
  height: 48px;
  width: 48px;
}

span.search-result-artist-name {
  color: #edf0fb;
  display: inline-block;
  font-family: Droid Sans;
  font-size: 14px;
  letter-spacing: 0.04em;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space:nowrap;
  width: 100%;
}

div#artistsToAddTitleContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

span#artistsToAddTitleNumber {
  color: #edf0fb;
  font-size: 18px;
  padding-right: 5px;
  transition: all 1s ease;
}

span#artistsToAddTitleNumber.zooming {
  font-size: 36px;
  transition: all 1s ease;
}

span#artistsToAddTitleText {
  color: #edf0fb;
  font-size: 18px;
  padding-right: 5px;
  text-transform: uppercase;
}

div#artistsToAddTitleIconContainer {
  padding-left: 20px;
}

span#artists-to-add-title-icon.collapsed {
  background-image: url("./right.png");
  background-size: 20px 20px;
  display: inline-block;
  height: 20px;
  width: 20px;
}

span#artists-to-add-title-icon.expanded {
  background-image: url("./down.png");
  background-size: 20px 20px;
  display: inline-block;
  height: 20px;
  width: 20px;
}

div.artist-to-add {
  align-items: center;
  background-color: rgba(13, 14, 18, .8);
  border: 1px solid #4f5367;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

div.artist-to-add-container {
  display: inline-block;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

img.artist-to-add-thumbnail {
  border-radius: 50%;
  height: 48px;
  width: 48px;
}

span.artist-to-add-name {
  color: #edf0fb;
  display: inline-block;
  font-family: Droid Sans;
  font-size: 14px;
  letter-spacing: 0.04em;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space:nowrap;
}

div#playlistError {
  margin-top: 50px;
}

.playlist-error-text {
  color: #edf0fb;
  line-height: 100%;
  font-size: 35px;
  text-transform: uppercase;
}

a.playlist-error-text {
  padding-left: 20px;
  text-decoration: underline;
}

.progressbar-text {
  font-size: 36px;
  line-height: 100%;
}

#goToPlaylistButtonContainer {
  padding-top: 50px;
}

#goToPlaylistButton {
  background-color: #95b30a;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  text-transform: uppercase;
}

#goBackButtonContainer {
  padding-top: 30px;
}

#goBackButton {
  background-color: #95b30a;
  border: none;
  border-radius: 50px;
  font-size: 15px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
}

.successfully-created {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #919691;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #919691;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #919691;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #919691;
}

.loading {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
